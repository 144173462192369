





















import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsTabs from '@/components/VsTabs/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'SuppressionListIndex',
    components: {
        VsContainer,
        VsTabs,
        VsSectionHeader,
        VsLoader,
    },
})
export default class extends Vue {
    private loading = false
    private refresh = 1
    $refs: any

    get activeTab () {
        return this.$route.name
    }

    get user () {
        return UserModule.user
    }

    get statisticsDisabled () {
        return !this.user?.configuration?.rules.suppressionFull
    }

    get tabs () {
        const tabs = [
            {
                label: this.$t('lists.suppression.tabs.list'),
                id: 'suppressionList',
                to: {
                    name: 'suppressionList',
                },
            },
            {
                label: this.$t('lists.suppression.tabs.statistics'),
                id: 'suppressionStatistics',
                disabled: this.statisticsDisabled,
                iconName: this.statisticsDisabled ? 'lock' : '',
                to: {
                    name: 'suppressionStatistics',
                },
                clickDisabled: this.openBlockedModal,
            },
            {
                label: 'Importazioni',
                id: 'suppressionImportHistory',
                to: {
                    name: 'suppressionImportHistory',
                },
            },
        ]

        return tabs
    }

    private openBlockedModal () {
        this.$root.$vsOpenBlockedModal()
    }

    private refreshSuppressionList () {
        this.refresh = this.refresh + 1
    }
}
